<template>
  <div class="">
    <b-row class="mb-2 align-items-center">
      <b-col
        sm="12"
        md="6"
        lg="8"
        class="d-flex justify-content-sm-center justify-content-md-start"
      >
        <b-form-group>
          <div class="d-flex flex-row align-items-center">
            <label class="mr-1"><strong>Kategori</strong></label>
            <b-form-select
              v-model="filteredBy"
              :options="paketCategories"
            ></b-form-select>
          </div>
        </b-form-group>
      </b-col>
      <b-col
        sm="12"
        md="6"
        lg="4"
        class="d-flex justify-content-sm-center justify-content-md-end"
      >
        <b-form-group class="blog-search" style="width: 400px">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="search-input"
              v-model="q"
              placeholder="Cari Paket"
            />
            <b-input-group-append class="cursor-pointer" is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-card no-body v-for="(item, i) in data" :key="i">
      <b-card-body>
        <div class="d-flex justify-content-between align-items-center">
          <div class="align-items-center text-body">
            <section>
              <h4>
                <strong> {{ item.name }}</strong>
              </h4>
            </section>
            <small class="text-muted"> {{ item.sum_subtest }} subtes</small>
          </div>
          <div class="d-flex align-items-center text-body">
            <b-button @click="lihatStatistik(item)" variant="outline-primary">
              Lihat Statistik
            </b-button>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
} from "bootstrap-vue";
import { $themeColors } from "@themeConfig";
const chartColors = {
  benarColor: "#e83b04",
  salahColor: "#b9483d",
  kosongColor: "#d09792",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          title: "Kedinasan",
          subtes: 3,
          time: "1 Januari 2023, 13:00 WIB s/d 7 Januari 2023, 18:00 WIB",
        },
        {
          id: 2,
          title: "UTBK SNBT",
          subtes: 7,
          time: "1 Januari 2023, 13:00 WIB s/d 7 Januari 2023, 18:00 WIB",
        },
      ],
      categoryId: null,
      filteredBy: null,
      paketCategories: [],
      q: null,
    };
  },
  watch: {
    filteredBy(categoryId) {
      if (categoryId) {
        this.categoryId = categoryId == "all" ? null : categoryId;
        this.$emit("loadKategori", this.categoryId);
      }
    },
    q: {
      handler(value) {
        if (this.q == "") {
          this.q = null;
        } else {
          this.$emit("load", this.q);
        }
      },
      deep: true,
    },
  },
  props: ["data"],
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  methods: {
    lihatStatistik(item) {
      this.$store.commit("paketTryout/SET_DETAIL", item);
      this.$router.push({
        name: "statistik-tryout-detail",
        params: { id: item.id },
      });
    },

    async getPaketCategories() {
      try {
        const response = await this.$store.dispatch(
          "paketTryout/indexKategoriPaket"
        );
        const paketCategories = response.data?.data;
        this.paketCategories.push({
          text: "-- Pilih Kategori Paket --",
          value: null,
        });
        this.paketCategories.push({
          text: "Semua Kategori",
          value: "all",
        });
        this.paketCategories.push(
          ...paketCategories.map((category) => ({
            text: category.name,
            value: category.id,
          }))
        );
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
  },

  async created() {
    await this.getPaketCategories();
  },
};
</script>

<style></style>
